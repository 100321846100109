.teachers-payroll__wrap {
    background-color: #fff;
    border: 2px solid var(--blue);
    border-radius: 10px;
    width: fit-content;
    max-width: 860px;

    padding: 10px 15px ;
}

.teachers-payroll__selectors {
    margin-bottom: 16px;
}

.teachers-payroll__totals-wrap {
    margin-bottom: 16px;
}

.teachers-payroll__totals-item {
    display: flex;
    margin-bottom: 8px;
}

.teachers-payroll__totals-item i {
    width: 200px;
}

.payroll-list__wrap {
    margin-bottom: 16px;
    margin-right: 24px;
}

.payroll-list__headline {
    font-weight: 400;
    font-style: italic;
    margin-bottom: 4px;
}

.payroll-item__wrap {
    margin-bottom: 8px;
}

.payroll-item__line {
    display: flex;
    cursor: pointer;
}

.payroll-item__line:hover {
    color: var(--blue);
    position: relative;
    left: 4px
}

.payroll-item__line:active {
    opacity: .4;
}

.payroll-item__label {
    width: 240px;
}

.teacher-payroll__list-wrap {
    display: flex;
    flex-wrap: wrap;
}