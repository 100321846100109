.analytics__filials-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

.analytics__filials-wrap table {
    margin-bottom: 25px;
    height: fit-content;
}

.analytics__filials-wrap caption {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.analytics__filials-wrap th {
    text-align: left;
    padding-left: 20px;
    border-bottom: 1px solid #464646;
    border-top: 1px solid #464646;
}

.analytics__filials-wrap td {
    padding-right: 5px;
}

.th-table__name {
    display: block;
    width: 200px;
}

.attendance-amount__wrap {
    display: flex;
    justify-content: space-around;
}

.analytics__student-counter {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
}

.analytics__student-counter p{
    font-size: 16px;
    margin-bottom: 8px;
}

.student-counter__wrap {
    border: 1px solid #464646;
    padding: 5px;
    margin-right: 8px;
    border-radius: 8px;
    margin-bottom: 8px;

    width: 320px;
}

.analytics__student-counter--top {
    display: block;
    margin-right: 16px;

}

.analytics__student-counter--top p {
    display: flex;
    width: 250px;
    justify-content: space-between;
    border-bottom: 1px solid #464646;
}

.ananlytc__top {
    display: flex;
    flex-wrap: wrap;
}