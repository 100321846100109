.parent-creation__wrap {
  padding: 15px;
  border: 2px solid var(--blue);
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-left: 25px;
}

.parent__active-cab {
  width: 10px;
  height: 10px;
  background: var(--green-gradient);
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 2px;
}

.parent__active-balance {
  width: 10px;
  height: 10px;
  background: var(--blue);
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 2px;
}

.parent__in-student {
  display: flex;
  align-items: center;
}

.paybycard__title {
  margin-right: 10px;
}

.paybycard__title--wide {
  width: 90px;
}

.paybycard__status {
  cursor: pointer;
}

.paybycard__status:hover {
  opacity: .6;
}