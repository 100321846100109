.alarm__containers {
    position: fixed;
    top:0px;
    left: 0px;

    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(7px);
    background: rgba(14, 28, 38, 0.3);
    z-index: 100;
}

.alarm__button-container {
    background: rgba(245, 245, 245, 0.6);
    border-radius: 13px;
    height: 120px;
    width: 360px;
}

.alarm__button {
    display: block;
    background: transparent;
    border: none;
    outline: 0;
    width: 100%;
    text-align: center;
    height: 60px;
    cursor: pointer;

    font-size: 20px;
    font-weight: 600;
}
.alarm__button--yes {
    border-radius: 13px 13px 0 0;
    border-bottom: 1px solid rgba(153, 153, 153, 0.5);
}

.alarm__button--no {
    border-radius: 0 0 13px 13px;
    color: #F2164B;
}

.alarm__button:hover {
    background: rgba(245, 245, 245, 0.8);
}

.alarm__button:active {
    background: #fff;
}

.alarm__description {
    width:360px;
    background: #F1F1F1;
    border-radius: 13px;
    font-size: 22px;
    padding: 12px 20px;

    box-sizing: border-box;
    margin-bottom: 15px;
}

.alarm-wrap {
    position: relative;
    left: 50%;
    margin-left: -180px;
    top: 50%;
    transform: translate(0, -50%);
}