.teacher__schedule {
    display: flex;
    margin-top: 24px;
    overflow-x: scroll;
}

.teacher-schedule__item {
    margin-bottom: 15px;
    width: 135px;
    margin-right: 10px;
    display: block;
    color: #000;
    text-decoration: none;

    border: 1px solid rgb(0, 147, 211);
    padding: 4px;
    border-radius: 10px;
}

.teacher-schedule__item:hover {
    opacity: .6;
}

.teacher-schedule__item:active {
    opacity: .4;
}

.teacher-schedule__headline {
    margin-bottom: 16px;
}

.schedule-item__field {
    margin-bottom: 8px;
}

.schedule-item__filial {
    font-size: 14px;
}

.schedule__filter-line {
    display: flex;
    width: 400px;
}

.schedule__filter-line button {
    display: block;
    border-radius: 10px;
    border:  2px solid rgb(0, 147, 211);
    background-color: #fff;
    color: #000;
    margin-left: 10px;
    font-size: 14px;
    font-family: 'Montserrat', Arial, sans-serif;
    cursor: pointer;
}

.schedule__filter-line button:hover {
    background-color: rgb(0, 147, 211);
    color: #000;
}

button.schedule__button--selected {
    background-color: rgb(0, 147, 211);
    color: #000;
}

button.schedule__button--selected:hover {
    cursor: inherit;
}