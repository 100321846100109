.main-page__headline{
    font-family: 'Play', Arial sans-serif;
    text-align: left;
    font-size: 42px;
    width: 520px;
    margin: 0;
    padding: 0;
}


.main-page-headline__wrap {
    padding-top: 80px;
    display: flex;
    justify-content: left;
    margin-bottom: 30px;
}

.main-page__headline--auth {
    justify-content: center;
}

.main-page__headline--auth h1 {
    text-align: center;
}

.auth-page__wrap .main-page__description{
    text-align: center;
}

.main-page__description {
    font-size: 16px;
    font-family: 'Roboto', Arial, sans-serif;
    text-align: left;
}
.main-page-description__wrap {
    display: flex;
    justify-content: left;
    width: 350px;
}

.main-page-btn {
    margin-top: 15px;
    text-decoration: none;
    color: #fff;
    font-weight: 800;
    background-color: #16a7e1;
    padding: 10px 25px;
    border-radius: 5px;
}

/* .main-page__img-wrap {
    position: absolute;
    z-index: -1;
    top: 0;
    min-width: 1100px;
    width: 100%;
    margin-top: 50px;
}

.main-page__img-wrap img {
    width: 100%;
    height: auto;
} */

.main-page__img-wrap {
    background: url('../img/coder_mount.png') no-repeat 0 0 transparent;
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    min-width: 1100px;
    z-index: -1;
}

.main-page__img-wrap::after {
    background: transparent;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 0.8) 50% ,rgba(0, 0, 0, 0.1) 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.main-page__tolesson-btn {
    margin-top: 20px;
}


@media screen and (max-width: 420px) {
    .main-page__img-wrap{
        min-width: 320px;
    }

    .main-page-headline__wrap {
        padding-top: 10px;
    }

    .main-page__headline {
        width: 280px;
        font-size: 24px;
    }

    .navbar .container {
        flex-direction: column;
    }

    .navbar-nav {
        flex-direction: column-reverse;
    }
    .nav-item {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .nav-bar__decor {
        display: none;
    }

    .nav__user-name {
        margin-right: 0;
    }

    .nav-item.nav__user-name {
        position: absolute;
        top: 5px;
        left: 10px;
        width: 120px;
    }
    .top-bar__logo-wrap {
        margin-bottom: 15px;
    }

    .nav-item.sign-btn {
        position: absolute;
        top: 5px;
        right: 10px;
    }
}