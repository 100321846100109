.creating-page__input-wrap {
    margin-bottom: 10px;
    width: 305px;
    display: flex;
}

.creating-page__input-wrap input{
    width: 100%;
    height: 30px;
    
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    border: 2px solid #a6a6a6;
}

.creating-page__input-wrap input:focus {
    border-color: #16A7E1;
}

.creating-page__input-wrap.recomend-price label{
    font-size: 14px;
    margin-right: 10px;
}

.teachers-select {
    display: block;
    width: 100%;
    height: 40px;
    
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    border: 2px solid #a6a6a6;
}

.teachers-select:focus {
    border-color: #16A7E1;
}

.group-creation {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.group-creation__log {
    width: 300px;
    height: 200px;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 5px 10px;
    margin-left: 35px;
    background-color: rgba(255, 255, 255, 0.8);
}

.group-creation__log h3 {
    padding: 5px;
    margin-top: 0;
    margin-bottom: 15px;
}

.student-creating {
    height: 170px;
}

.lesson-creation__wrap {
    display: flex;
    flex-direction: column;
    margin-top: -53px;
}

.lesson-create__btn {
    background: linear-gradient(228.63deg, #0093D3 -19.63%, #0260AC 5.86%, #00A7E2 91.58%);
    color: #fff;
    border: none;
    border-radius: 20px;
    font-weight: 800;
    font-size: 16px;
    height: 45%;
    cursor: pointer;

    margin-top: 23px;
}

.lesson-creation__wrap button img{
    width: 35px;
}

.lesson-creation__wrap button:active {
    opacity: .7;
}