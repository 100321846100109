.lesson-mgmnt__wrap {
    display: flex;
    width: 500px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
}

.mgmnt__btn {
    border: none;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    height: 40px;
    font-size: 14px;
    background-color: #ffba0f;
    cursor: pointer;
    margin-left: 15px;
    padding: 0 15px;
}
.mgmnt__btn:hover {
    opacity: .7;
}

.mgmnt__btn:active {
    box-shadow: #bbb 0px 0px 5px;
}

.lesson-mgmnt__status,
.lesson-mgmnt__mounth,
.lesson-mgmnt__year {
    display: block;
    width: 200px;
    height: 40px;
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    border: 2px solid #a6a6a6;
    margin-left: 10px;
}

.lesson-mgmnt__year {
    height: 40px;
    width: 80px;
    box-sizing: border-box;
}

.onetime__list--mngmnt {
    margin-bottom: 60px;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    max-height: 330px;
    overflow: hidden;
}

.onetime__list--mngmnt--opened {
    overflow: visible;
    max-height: initial;
}

.onetime-list__item {
    width: 120px;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 10px;

    min-height: 160px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 1px solid #bbb;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
}

.onetime-list__date {
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.onetime-list__lesson-type {
    margin-bottom: 10px;
}

.onetime-list__group-name {
    font-weight: bold;
    margin-bottom: 10px;
}

.lesson-mgmnt__label {
    padding: 5px 13px;
}

.group__online-indicator {
    width: 10px;
    height: 10px;
    background-image: url('../img/onlineIndicator.svg');
    background-size: cover;
    position: absolute;
    box-sizing: border-box;
    left: 8px;
    top: 8px;
}

.payment--page__searching {
    position: absolute;
}

@media screen and (max-width: 420px) { 
    .lesson-mgmnt__wrap {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .lesson-mgmnt__status, .lesson-mgmnt__mounth, .lesson-mgmnt__year {
        margin-left: 0;
    }

    .mgmnt__btn {
        margin-left: 0;
        margin-top: 20px;
        align-self: flex-end;
        width: 100%;
    }

}