.price__amount-field {
    width: 35px;
}

.price__amount-form {
    text-align: center;
    position: relative;
}

.group-info__header .add-to-group__wrap{
    align-self: end;
    padding-bottom: 75px;
}

.price__table td {
    padding: 0;
    font-size: 14px;
}

.price__table {
    width: 145px;
    box-sizing: border-box;
    margin-right: 5px;
}

.price__amount-btn {
    position: absolute;
    bottom: -25px;
    left: 0;
}

.price__list {
    display: flex;
}

.group-info__actual-course {
    min-height: 50px;
}

.schedule__list {
    padding-top: 16px;
}

.schedule__item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.hour-info__del-btn {
    display: block;
    background: var(--red-gradient);
    border-radius: 10px;
    padding: 3px 10px;
    border: none;

    font-family: 'Montserrat', Arial, sans-serif;
    color: #fff;
    cursor: pointer;
}

.hour-info__del-btn:hover {
    opacity: .6;
}

.hour-info__del-btn:active {
    opacity: .4;
}

.schedule-item__time {
    margin-right: 10px;
}

.schedule-item__weekday {
    font-weight: bold;
    margin-right: 5px;
}


.schedule {
    margin-top: 24px;
    border: 2px solid var(--blue);
    border-radius: 10px;
    width: fit-content;
    padding: 10px;
    margin-left: -10px;
    padding-right: 16px;
}

.schedule__creating-form {
    border-top: 1px solid var(--blue);
    padding-top: 10px;

    display: flex;
}

.schedule__creating-form select {
    margin-right: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 2px solid #0260AC;
}

.schedule__creating-form input {
    border-radius: 5px;
    border: 2px solid #0260AC;
    padding: 5px 10px;
    font-size: 14px;
    margin-right: 8px;
}

.schedule__creating-form button[type=submit] {
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    font-size: 14px;

    background: var(--blue-gradient);
    color: #fff;
}

.group__special-type {
    margin-top: 16px;
    padding: 5px 10px;
    margin-left: -10px;
    border: 1px solid #0260AC;
    width: fit-content;
    border-radius: 5px;

    display: flex;
    align-items: center;
}

.group__special-type input[type=checkbox] {
    display: block;
    margin-left: 6px;
    font-size: 16px;
    color: #0260AC;
}