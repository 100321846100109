.auth-page__form {
    width: 350px;
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    background-color: #16a7e1;
    border-radius: 20px;
    margin-top: 30px;
}

.auth-page__form input {
    background-color: #fff;
    height: 30px;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 2px 5px;
    border: 0
}

.auth-page__form button {
    height: 30px;
    border-radius: 5px;
    border: 0;
    background-color: #ffba0f;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.auth-page__form button:active {
    color: #000;
    opacity: .6;
}

.auth-page__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}
