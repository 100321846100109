.navbar .container {
    display: flex;
    justify-content: space-between;
    min-height: 80px;
    align-items: center;
}

.navbar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.navbar .container a {
    text-decoration: none;
    color: rgb(29, 29, 29);
}

.nav-item {
    margin-left: 15px;
}

.nav-item:hover {
    text-decoration: underline;
}

.sign-btn:hover {
    text-decoration: none;
}

.top-bar__logo-wrap {
    width: 50px;
    display: flex;
    align-items: center;
}

.top-bar__logo-wrap img {
    width: 100%;
    height: auto;
}

.teacher-info {
    display: flex;
    font-size: 14px;
}

.teacher-info__item {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.8);
    min-width: 115px;
    text-align: center;
    padding: 6px 12px;
    margin-right: 15px;
    box-sizing: border-box;
}

.teacher-info__hours {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.teacher-info__hours:hover {
    opacity: .8;
}

.teacher-info__hours:active {
    box-shadow: none;
}

.teacher-info__value {
    padding: 4px 15px;
    color: #fff;
    background-color: #0093D3;
    font-weight: bold;
    font-family: "Roboto", "Arial", sans-serif;
    border-radius: 5px;
    min-width: 84px;
    box-sizing: border-box;
}

.teacher-info__description {
    font-family: "Roboto", "Arial", sans-serif;
    margin-bottom: 8px;
}

.nav__user-name {
    margin-right: 30px;
    margin-left: 50px;
    font-size: 14px;
}

.exit__btn {
    font-size: 14px;
    border: none;
    background-color: transparent;
    font-weight: bold;
    color: #EA3857;
    cursor: pointer;
    font-size: 18px;
}
.exit__btn:hover {
    opacity: .6;
}

.nav-bar__decor {
    width: 120px;
    height: 3px;
    background: linear-gradient(to left,#00A7E2 0%,#00A7E2 50%, #B2B2B2 50%, #B2B2B2 100%);
    margin-left: 40px;
}

.teacher-info__cash {
    border: 2px solid #00A7E2;
    background: transparent;
}

.teacher-info__cash-wrap {
    position: relative;
    
}

.cash__debtor {
    cursor: pointer;
}

.cash__debtor:hover {
    opacity: .8;
}

.cash__debtor:active {
    opacity: .6;
}

.review-debt__desction-wrap {
    position: absolute;
    top: 72px;
    left: -100px;
    width: 200px;
    background-color: #fff;
    border: 2px solid #B2B2B2;
    padding: 5px 10px;
    padding-bottom: 0;
    border-radius: 10px;
}



.review-debt__desction-item {
    margin-bottom: 16px;
}

.review-debt__desction-item b {
    margin-right: 4px;
}