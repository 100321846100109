.students-page__wrap {
    width: 100%;
    display: flex;
}

.group-list__item--selected {
    background-color: rgb(123, 193, 237);
}

.field-line {
    display: flex;
    margin-bottom: 5px;
    min-height: 30px;
    
    align-items: center;
}

.student-card__field-tag {
    margin-right: 5px;

    box-sizing: border-box;
    padding-left: 10px;
    min-height: 30px;
    border-radius: 10px;
    border: 2px solid #a6a6a6;
    width: 160px;
    margin-right: 5px;
}

.field-title {
    width: 160px;
}

.student-card__wrap {
    position: fixed;
    right: 8%;
    top: 8%;
    background-color: #fff;
    width: 420px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: 2px solid #00A7E2;
    height: 600px;
    overflow-y: scroll;
}

.balance-card--income {
    background-color: var(--green);
}

.student-card__input-field {
    min-height: 22px;
    display: flex;
    align-items: center;
}

.student-list__header {
    display: flex;
    justify-content: space-between;
}

.student-list__header button{
    display: none;
}

.student-card__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;

    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    
    color: #fff;
    font-size: 12px;

    background: var(--red-gradient);

    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.student-list__search-field {
    font-size: 14px;
    box-sizing: border-box;
    display: block;
    border: 2px solid #a6a6a6;
    width: 100%;
    padding: 7px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.student-card__amount-input {
    border: 1px solid #00A7E2;
}



@media screen and (max-width: 420px) {
    .student-card__wrap {
        left: 10px;
        width: 90vw;
    }

    .student-list__header button{
        display: block;
    }
}