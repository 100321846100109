.group-list__item {
    min-width: 300px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 5px;
    border-bottom: 1px solid #bbb;

}

.group-item__name {
    margin-right: auto;
}

.archive-page__wrap {
    display: flex;
}

.archive-page__group-wrap {
    margin-right: 50px;
    min-width: 300px;
}

.student-card__filials {
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
}

.manage-page__links {
    display: flex;
    margin-bottom: 20px;
    width: 600px;
    justify-content: space-between;
}

.balance-card__table {
    display: block;
    height: 500px;
    font-size: 12px;

    overflow-y: scroll;
}

.balance-card__table td {
    padding: 5px 10px;
}

.balance-table__info {
    width: 200px;
}

.balance-card__balance-sum {
    margin-top: 10px;
    margin-bottom: 10px;

    font-size: 14px;
}

.student-card__notify-btn {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--blue);
    border: none;
    border-radius: 5px;
    padding: 2px 5px;
    color: #fff;
    cursor: pointer;
}

.student-card__notify-btn:hover {
    opacity: .8;
}

.student-card__notify-btn:active {
    opacity: .6;
}

.last-notify__date {
    display: block;
    margin-left: 10px;
    font-style: italic;
    font-size: 14px;
}

.balance-table__sum {
    width: 80px;
}

@media screen and (max-width: 420px) {
    .archive-page__wrap {
        flex-direction: column-reverse;
    }

    .manage-page__links {
        width: 100%;
        flex-direction: column;
    }

    .manage-page__links a{
        padding: 10px 0px;
    }
}