/* switch */

/* The switch - the box around the slider */
.switch {
    --size: 40px;
    --height:  calc(var(--size) * 0.56);
    --slider-size: calc(var(--size) * 0.43);
    --step: calc(var(--size) * 0.43);
    position: relative;
    display: inline-block;
    width: var(--size);
    height: var(--height);
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: var(--slider-size);
    width: var(--slider-size);
    left: calc(var(--size)* 0.066);
    bottom: calc(var(--size)* 0.066);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #1d9ef5;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #1d9ef5;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(var(--step));
    -ms-transform: translateX(var(--step));
    transform: translateX(var(--step));
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }