.review__wrap {
    border: 1px solid #000;
    border-radius: 20px;
    padding: 20px 15px;

    position: absolute;
    top: -150px;
    background-color: #fff;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.review__wrap h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
}

.review__wrap form {
    display: flex;
    flex-direction: column;
}

.review__wrap input,
.review__wrap select,
.review__wrap textarea  {
    margin-bottom: 10px;
}

.review__wrap select {
    font-size: 16px;
}

.mgmnt__btn--review {
    margin-left: 0;
    height: 30px;
}

.review__wrap textarea {
    border: 1px solid #a6a6a6;
    border-radius: 5px;
}

.review__wrap h3 {
    text-align: center;
}

.review__wrap h4 {
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
}

.review__open-btn {
    border-radius: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #0260AC;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
}

.review__delete-btn {
    margin-top: 16px;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #000;
    width: 100%;
}

.review__delete-btn:hover {
    background:  var(--red-gradient);
    color: #fff;
}

.review-modal__btn {
    margin-top: 16px;
    margin-right: 8px;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #000;
}

.review-modal__btn:hover {
    opacity: .6;
}

.review__grid {
    display: grid;
    grid-template-columns: 0.2fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr;
    grid-gap: 5px;

    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #a6a6a6;
}

.review__grid--admin {
    grid-template-columns: 1fr 0.3fr 1.5fr 1fr 0.5fr 1.5fr 0.5fr 0.5fr;
}

ul.review__grid {
    margin-bottom: 15px;
    font-weight: bold;

    border: none;
    font-size: 18px;
}

.review__comment {
    white-space: pre-line;
    cursor: pointer;
}

.review__comment:hover {
    opacity: .6;
}

.review-page__comment-wrap textarea{
    font-size: 16px;
    font-family: 'Montserrat', Arial, sans-serif;
}

.review-filter__wrap {
    margin-bottom: 25px;
}

.review-filter__wrap :nth-child(1n) {
    margin-right: 10px;
}

.student-list__review-call {
    display: block;
    width: 15px;
    margin-left: 10px;
    background: linear-gradient(116.26deg, #F2164B 33.48%, #DA7E70 96.9%);
    text-align: center;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.review__delte-btn-wrap {
    display: flex;
    flex-direction: column;

}

.review-elem__wrap {
    display: flex;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #0260AC;
    border-radius: 10px;
    background-color: #fff;
}

.review-elem__info {
    width: 300px;
}

.review-elem__text {
    width: 620px;
    margin-right: 8px;
    line-height: 1.3;
}

.review__next-page-btn {
    display: block;
    border: 0;
    width: 100%;
    padding: 16px 0;
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    background: var(--blue-gradient);
    color: #fff;
    cursor: pointer;
}

.review__next-page-btn:hover {
    opacity: .8;
}

.review__next-page-btn:active {
    opacity: .6;
}

.review-page__container {
    padding-bottom: 24px;
}

.review__loading-wrap {
    min-height: 52px;
}

.review__debtors-link {
    display: block;
    margin-bottom: 16px;
    padding: 5px 15px;
    border-radius: 10px;
    color: #fff;
    background: #0260AC;
    width: fit-content;
}

.review__debtors-link:hover {
    opacity: .8;
}

@media screen and (max-width: 460px) {
    .review__grid {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        grid-column-gap: 15px;
    }

    .review__grid div {
        margin-bottom: 10px;
    }

    .review__comment {
        grid-column: 1 / 3;
    }

    .review-elem__wrap {
        flex-direction: column;
    }

    .review-elem__text {
        width: 300px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .yc_button.review__approve-btn {
        height: auto;
    }

    .review__delte-btn-wrap {
        grid-column: 1 / 3;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .review__delte-btn-wrap button {
        width: 50%;
    }

    ul.review__grid {
        display: none;
    }
}