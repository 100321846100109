.profile__line {
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-bottom: 10px;
  }
  
  .profile__line b {
    width: 250px;
  }
  
  .profile__line input{
    box-sizing: border-box;
    padding-left: 10px;
    min-height: 30px;
  
    border-radius: 10px;
    border: 2px solid #a6a6a6;
    width: 180px;
    margin-right: 5px;
  }
  
  input.profile__checkbox {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    width: 30px;
  }
  
  .profile__submit-btn {
    height: 30px;
    background: linear-gradient(111.26deg, #C1D4B6 -7.84%, #5DBC26 64.01%);
    color: #fff;
  
    border: none;
  
    border-radius: 50px;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .profile__submit-btn:hover {
    opacity: .7;
  }
  
  .profile__submit-btn:active {
    opacity: .3;
  }
  
  .teacher__name-column {
    padding-left: 0;
  }
  
  td {
    /* border-bottom: 1px solid #a6a6a6; */
    padding: 10px 10px;
  }
  
  .teachers__table {
    border-collapse: collapse;
    position: relative;
    width: 100%;
  }

  .teachers__table tr {
    width: 100%;
    border-bottom: 1px solid #a6a6a6;
  }

  .teachers-arch-btn__wrap {
    position: absolute;
    border-bottom: none;
    right: 0;
    margin-top: 20px;
  }

  
  .profile__value {
    cursor: pointer;
  }
  
  .profile__value:hover {
    opacity: .6;
  }

  @media screen and (max-width: 420px) {
    .teachers__table tr {
      display: grid;
      grid-template-columns: 1fr ;
      margin-bottom: 20px;
      
      border: 2px solid #a6a6a6;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 5px;

    }

    .teachers__table td {
      padding: 2px 2px;
      width: 300px;
    }

    td.teachers-arch-btn__wrap {
      width: 50px;
      margin-top: -5px;
    }
  }